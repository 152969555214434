// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\web\\lechtaler-website\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casting-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\casting.js" /* webpackChunkName: "component---src-pages-casting-js" */),
  "component---src-pages-datenschutz-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produkte-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-rezepte-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\rezepte.js" /* webpackChunkName: "component---src-pages-rezepte-js" */),
  "component---src-pages-teilnahmebedingungen-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\teilnahmebedingungen.js" /* webpackChunkName: "component---src-pages-teilnahmebedingungen-js" */),
  "component---src-pages-ueber-uns-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-wiesn-gewinnspiel-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\wiesn-gewinnspiel.js" /* webpackChunkName: "component---src-pages-wiesn-gewinnspiel-js" */),
  "component---src-pages-wo-kaufen-js": () => import("D:\\web\\lechtaler-website\\src\\pages\\wo-kaufen.js" /* webpackChunkName: "component---src-pages-wo-kaufen-js" */)
}

exports.data = () => import("D:\\web\\lechtaler-website\\.cache\\data.json")

