module.exports = [{
      plugin: require('D:/web/lechtaler-website/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/web/lechtaler-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-127399572-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('D:/web/lechtaler-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
