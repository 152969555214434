const CookieControl = require('@guplabs/cookiecontrol');
require('@guplabs/cookiecontrol/dist/cookiecontrol.css');
const BrowseHappy = require('./src/helper/browseHappy');

exports.onClientEntry = () => {
    /* eslint-disable no-unused-vars */
    BrowseHappy.browseHappy();
    if (process.env.NODE_ENV === 'production') {
        const cookieControl = new CookieControl({
            type: 'info',
            cookieConsentOptions: {
                showLink: false,
                position: 'bottom-left',
                palette: {
                    popup: {
                        background: '#ffffff',
                    },
                    button: {
                        background: 'transparent',
                        border: '#0E5A99',
                        text: '#0E5A99',
                    },
                },
                cookie: {
                    expiryDays: 90,
                    domain: process.env.GATSBY_DOMAIN,
                },
            },
            translations: {
                de: {
                    messages: {
                        info:
                            'Um unser Web-Angebot optimal präsentieren und verbessern zu können, verwenden wir Tracking-Tools. Mit der fortgesetzten Nutzung unserer Website gehen wir von deiner Zustimmung aus. <a style="color: #0E5A99" href="/datenschutz" target="_blank">Details</a>',
                    },
                    dismiss: {
                        info: 'Verstanden',
                    },
                },
            },
        });
    }
    /* eslint-enable no-unused-vars */
};

// Polyfills für IE 10 (@see https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-382280801)
// React braucht diese Polyfills, und die werden nicht automatisch von Babel gepolyfilled, da es in
// node_modules hängt.
require('core-js/fn/set');
require('core-js/fn/map');
require('core-js/fn/array/from');
require('core-js/fn/object/entries');
require('core-js/fn/string/starts-with');

// IO Polyfill für Safari und iOS
require('intersection-observer');
